import Vue from 'vue'
import Router, { Route } from 'vue-router'

import AppAsid from '@/pages/app/App_Asid.vue'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'is-active',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '/',
      name: 'app',
      component: () => import(/* webpackChunkName: "App_Index.vue" */ '@/pages/app/App_Index.vue'),
      props: true
    },
    {
      path: '/asid-scanner',
      name: 'asid-scanner',
      component: () => import(/* webpackChunkName: "App_AsidScanner.vue" */ '@/pages/app/App_AsidScanner.vue'),
      props: true
    },
    {
      path: '/:asid',
      name: 'asid',
      component: AppAsid,
      props: true,
      children: [
        {
          path: '*',
          name: 'app-asid-childroute'
        }
      ]
    }

    // {
    //   path: '/feedback',
    //   name: 'feedback',
    //   // route level code-splitting
    //   // this generates a separate chunk (feedback.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "feedback" */ './views/Feedback.vue')
    // }
  ]
})

router.beforeEach((to: Route, from: Route, next: any) => {
  next()
})

export default router
