/**
 *
 * @param progress 0..100
 * @param estimatesTime time it takes to reach this progress (0: immediate)
 */
export function setGlobalProgress(progress: number, estimatesTime = 0, text = '', suffixProgress = false) {
  if ((window as any).echo_fullscreenloading) {
    (window as any).echo_fullscreenloading.setProgress(progress, estimatesTime, text, suffixProgress)
  } else {
    console.error('window.echo_fullscreenloading not found')
  }
}
