
import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faArrowUp, faLanguage, faExclamationCircle } from '@fortawesome/free-solid-svg-icons'

const getModuleManagerApp = import('@/modules/moduleManagerApp')

import { TenantID } from '../../types/typeTenant'
import { setGlobalProgress } from '@/helpers/globalProgress'
import { LegalConfig } from '@/types/typeAppConfig'
import { GlobalAdminNotification } from '@/types/typeAdmin'

const AdminNotificationManager = import('@/database/adminNotificationManager')
import VApp from '@/pages/app/components/VApp.vue'
import { defaultLegalConfig } from '@/database/databaseSchema'
import { setUrlPathPrefix } from './appStateRouter'

import * as Sentry from '@sentry/browser'
import { RPCAppDataResponse } from '@/types/typeRPC'
import { handlePromiseError } from '@/helpers/notificationHelper'
// lazy load Veditor as this contains the large sanatise-html bundle
const VEditorHtml = () => import('@/components/global/VEditorHtml.vue')


library.add(faArrowUp, faLanguage, faExclamationCircle)

@Component({
  components: { VApp, VEditorHtml }
})
export default class AppAsid extends Vue {

  @Prop({ type: String, required: true })
  public asid!: string

  public isLoading = true
  public isInitialLoading = true
  public modulesAppData: RPCAppDataResponse = {
    moduleGroupAppDatas: [],
    globalData: { tenantID: '', sessionID: '', dataHash: '', legal: defaultLegalConfig }
  }
  public tenantId: TenantID = ''
  public legal: LegalConfig = { ...defaultLegalConfig }
  public errorText = ''
  public loadingProgress = 0

  private GLOBAL_NOTIFICATION_CHECK_EVERY_SECONDS = 60 * 5

  public appPath = ''

  @Watch('appPath')
  private async onAppPathChanged() {
    const path = `/${this.asid}${this.appPath && '/' + this.appPath}`

    if (path !== this.$route.path)
      await this.$router.push(path)
  }

  @Watch('$route.path', { immediate: true, deep: true })
  private onUrlPathChanged() {
    const p = this.$route.path
    // remove /<asid>/ from path
    const path = p.indexOf('/', 1) < 0 ? '' : p.substring(p.indexOf('/', 1) + 1)
    this.appPath = path
  }

  private protectionKeys: string[] = []

  public async onKeyProvided(key: string) {
    this.protectionKeys.push(key)
    this.$helpers.localStorage.set('protectionKeys', this.protectionKeys, Array)
    await this.loadData()
  }

  public async created() {
    await this.loadData()

    setUrlPathPrefix(this.asid)
  }


  public async loadData() {
    // this.protectionKeys = this.$helpers.localStorage.get('protectionKeys', [] as string[], Array)

    setGlobalProgress(73, 2, 'Loading', true)
    // dynamically import the module manager to enable code splitting
    const ModuleManagerApp = (await getModuleManagerApp).default

    try {
      setGlobalProgress(92, 2, 'Loading', true)
      this.modulesAppData = await ModuleManagerApp.getAppData(this.asid, this.protectionKeys)

      Sentry.setTag('session_id', this.modulesAppData.globalData.sessionID)
      Sentry.setTag('tenant_id', this.modulesAppData.globalData.tenantID)
      Sentry.setTag('asid', this.asid)
      //#region preload vue dynamic imports to prevent flickering

      const moduleGroupsOverviewVue = ModuleManagerApp.getOverviewsTuple(this.modulesAppData.moduleGroupAppDatas)
      const moduleGroupsBackgroundVue = ModuleManagerApp.getBackgroundTuple(this.modulesAppData.moduleGroupAppDatas)

      console.log(this.modulesAppData)

      setGlobalProgress(99, 1, 'Loading', true)
      // wait until everything is loaded to prevent flickering
      await Promise.all(
        [...moduleGroupsOverviewVue, ...moduleGroupsBackgroundVue]
          .filter((mo) => typeof mo.component === 'function') // is dynamic lazy import
          .map(mo => {
            if (!mo.component) return
            const importFnc = mo.component() //call function
            return ('component' in importFnc) ? importFnc.component : importFnc //is component object
          }))

      console.log('all loaded')

      //#endregion preload vue dynamic imports

    } catch (e: any) {
      this.errorText = `
${e.toString()}

Possible reasons:
- Network connection issue (try to reload the page)
- This code has not been activated in the Backend
- No references are assigned to this code
- Traffic is blocked by firewall

Error ID: [20221021]`
      console.error(this.errorText)
    } finally {
      this.isLoading = false
      console.log('progress 100%')

      setGlobalProgress(100, 0)
      this.isInitialLoading = false
    }
  }

  public globalNotification: GlobalAdminNotification = {
    // shown on every page in backend
    text: '',
    show: false,
    type: 'info',
    blockBackend: false,
    reshowEverySeconds: 60 * 5
  }
  public globalNotificationActive = false

  private reshowNotificationInterval: any = undefined
  private displayGlobalNotification() {
    handlePromiseError(async () => {
      try {
        // async import for code splitting
        (await AdminNotificationManager).default.getAdminNotification().then((notification) => {
          this.globalNotification = notification.appNotification

          clearInterval(this.reshowNotificationInterval)

          const applyShow = () => {
            this.globalNotificationActive = this.globalNotification.show
          }
          this.reshowNotificationInterval = setInterval(applyShow, this.globalNotification.reshowEverySeconds * 1000)
          applyShow()
        })
          .catch((e) => {
            console.error('get notification failed', e)
          })

      } catch (error) {
        console.error('get notification failed', error)
      }
    })
  }

  public mounted() {
    // get global notification
    setInterval(this.displayGlobalNotification, 1000 * this.GLOBAL_NOTIFICATION_CHECK_EVERY_SECONDS)

    this.displayGlobalNotification()
  }
}
