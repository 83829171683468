import '@/class-component-hooks'

import helpers from '@/helpers/globalHelpers'
import i18n from '@/modules/_globals/i18nGlobals'
import { DynamicReactiveRefs } from 'vue-reactive-refs'

import Vue from 'vue'
import Buefy from 'buefy'
// import { firestorePlugin } from 'vuefire'

import router from './router'
import App from '@/App.vue'

import '@/main.scss'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'
import { CaptureConsole } from '@sentry/integrations'

console.log('NODE_ENV', process.env.NODE_ENV)
console.log('VUE_APP_ENV', process.env.VUE_APP_ENV)

if (process.env.NODE_ENV !== 'development')
  Sentry.init({
    Vue,
    dsn: 'https://0567fcf2ced1460abac91fc6befa1df2@o1296785.ingest.sentry.io/6705320',
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracingOrigins: ['127.0.0.1', 'localhost']
      }),
      new CaptureConsole({
        levels: ['error']
      })
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    debug: ['local', 'dev'].includes(process.env.VUE_APP_ENV || ''),
    environment: process.env.VUE_APP_ENV,
    release: `${process.env.VUE_APP_VERSION}_${(+(process.env.VUE_APP_BUILD_TIME || 1)).toString(36)}`,
    tracesSampleRate: 1.0,
    logErrors: true
  })

Vue.use(DynamicReactiveRefs)
Vue.component('FontAwesomeIcon', FontAwesomeIcon)

Vue.use(Buefy, {
  defaultIconPack: 'fas',
  defaultIconComponent: 'font-awesome-icon',
  defaultNotificationPosition: 'is-top',
  defaultNotificationDuration: 10000
})

// Vue.use(firestorePlugin as any, { wait: true }) not used in the app

Vue.use(i18n)
Vue.use(helpers)

Vue.filter('lowercase', function (value: string) {
  if (!value) return ''
  return value.toLowerCase()
})

Vue.config.productionTip = false
;(window as any).VueInst = new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
